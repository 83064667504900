/*
* Compatibility file
* to use '@sc/portal.fe.lib.global-styles' and deprecate '@sc/global-styles'
*/
// Utils
@import '../node_modules/bootstrap/scss/utilities/flex';

// Overwrite Input styles
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
select {
  height: inherit;
}

// Overwrite Button styles
.sc-button {
    height: inherit;
    &--link {
      text-decoration: none;
    }
    &.full-width {
        width: 100%;
    }
}

.budget__btn__more {
  text-decoration: none !important;
}
