@import '@sc/portal.fe.lib.global-styles/src/globals';

@media only screen and (min-width: map-get($grid-breakpoints, md)) {
  .base-text-input {
    &__label {
      &--as-placeholder {
        font-size: 0.875rem !important;
      }
    }
  }
}