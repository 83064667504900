/* You can add global styles to this file, and also import other style files */
@import './styles/base';
@import '@sc/portal.fe.lib.global-styles/src/globals';
// Custom "Siniestros" Grid container-max-widths
$container-max-widths: map-merge($container-max-widths, (sm: auto, md: 100%));
@import '@sc/portal.fe.lib.global-styles/src/base';
@import 'global-styles';
@import "bootstrap/scss/_tooltip";
@import 'ngx-toastr/toastr-bs4-alert';
// Custom "Siniestros" Page Layout
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100%;
  header,
  footer {
    position: relative;
    flex-shrink: 0;
  }
  header.with-header-info {
    margin-top: 35px;
  }
  main {
    flex: 1;
    position: relative;
    &.main-admin {
      height: 100%;
    }
  }
  footer {
    overflow: hidden;
  }
}

@include media-breakpoint-down(sm) {
  .wrapper {
    header.with-header-info {
      margin-top: 80px;
    }
    main {
      &.main-admin {
        height: calc(100% - 48px);
        margin-top: 48px;
      }
    }
  }

}

.pas-detail-list {
  &__row {
    @include media-breakpoint-down(sm) {
      text-align: left;
    }
  }

  &__column {
    .wrap-text {
      white-space: pre-line;
    }

    .secondary {
      font-weight: 400;
    }

    .sc-tooltip {
        color: $primary-brand;
        font-size: 1rem;
        cursor: pointer;
    }

    .sc-tooltip__content {
        .content__description {
            text-align: left !important;
        }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .wrapper {
    main {
      flex: 1 0 auto;
    }
  }
}

table.sc-table {
  width: 100%;
  >tbody>tr {
    position: relative;
    td {
      max-width: 1px;
    }
  }
}


@media (max-width: 360px) {
  #fraudModal {
    .sc-modal-dialog {
      border-radius: 12px;
      &__container {
        max-height: 450px !important;
      }
    }

  }
}

#toast-container {
  &.toast-container {
    .sc-toast {
      padding: 1rem;
      &.toast {
        &-success {
          background-image: none;
          background-color: $secondary-green;
          color: #fff;
        }
        &-info {
          background-image: none;
          background-color: $primary-brand;
          color: #fff;
        }
        &-error {
          background-image: none;
          background-color: $secondary-red-dark;
          color: #fff;
        }
      }
      &__input {
        &-important {
          display: flex;
          align-items: center;
          &__icon {
            margin-right: .5rem;
            color: #fff;
          }
          &__content {
            width: 100%;
          }
        }
        &-wrapper {
          width: 100%;
        }
      }
    }
  }
}

.pas-indicators__box-title--button__tooltip,
.pas-indicators__box-info__secondary__title,
.service-order__tooltip-block {
  position: relative;
  display: block;

  .tooltip {
    position: absolute;
    pointer-events: auto !important;

    .tooltip-inner {
      background-color: $white;
      border: 1px solid $grey-2;
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0, 28, 51, 0.06);
      color: $primary-text-dark;
      font-size: .875rem;
      max-width: 350px;
      padding: 1rem;
      text-align: center;
      min-width: 316px;
    }
  }

  .tooltip.show {
    opacity: 1;
  }

  .tooltip.top,
  .tooltip.bottom {
    .tooltip-arrow::before {
      pointer-events: none;

    }
  }

  .tooltip.left,
  .tooltip.right {
    .tooltip-arrow::before {
      pointer-events: none;
    }
  }

  .tooltip.top .tooltip-arrow::before {
    border-top-color: $grey-2;
  }

  .tooltip.left .tooltip-arrow::before {
    border-left-color: $grey-2;
  }

  .tooltip.bottom .tooltip-arrow::before {
    border-bottom-color: $grey-2;
  }

  .tooltip.right .tooltip-arrow::before {
    border-right-color: $grey-2;
  }

}

.timeline {
  background: #FFFFFF;
  padding: 0 !important;
  margin: 0 auto;
  td {
    padding: 0 !important;
    height: auto !important;
    &.timeline-empty {
      background: #FFFFFF;
    }
  }
  &-item {
    display: block;
    height: 1rem;
    &::before {
      content: '';
      height: inherit;
      display: block;
      background: #FFFFFF;
      float: right;
    }
    &.f-l {
      &::before {
        float: left;
      }
    }
    &.f-r {
      &::before {
        float: right;
      }
    }
    &.size-0 {
      &::before {
        width: 0;
      }
    }
    &.size-1 {
      &::before {
        width: 1rem;
      }
    }
    &.size-2 {
      &::before {
        width: 2rem;
      }
    }
    &.size-25 {
      &::before {
        width: 25%;
      }
    }
    &.size-50 {
      &::before {
        width: 50%;
      }
    }
    &.size-75 {
      &::before {
        width: 75%;
      }
    }
    &.size-100 {
      &::before {
        width: 100%;
      }
    }
    &-start,
    &-end {
      height: 2rem;
      &::before {
        content: '';
        height: inherit;
        display: block;
        background: #FFFFFF;
        width: 100%;
      }
    }
    &-space {
      height: .5rem;
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: inherit;
        background: #FFFFFF;
      }
    }
  }
  &.animated-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F7F8;
    background: linear-gradient(to right, #EEEEEE 8%, #DDDDDD 18%, #EEEEEE 33%);
    background-size: 1024px;
    width: 100%;
    position: relative;
  }
}

.sc-card {
  border-radius: 3px;
  background-color: #FFFFFF;
  border-left: 8px solid #0070CC;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1), 0 18px 16px -12px rgba(0, 112, 204, .15);
  &--valid {
    border-left: 8px solid #3DCC98;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .1), 0 18px 16px -12px rgba(61, 204, 152, .15);
  }
}

.break-word {
  word-break: break-word;
}

.multilinetext {
  white-space: pre-line;
}

/* Remove IE 'clear field' X button on inputs */

::-ms-clear {
  display: none;
}

/* Remove IE 'clear field' X button for password field */

::-ms-reveal {
  display: none;
}

/* Remove spin button from number inputs */

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

/* Firefox */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number']:hover::-webkit-inner-spin-button,
input[type='number']:hover::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@include media-breakpoint-down(sm) {
  table.sc-table {
    display: block;
    /*Required for wrap to work correctly in IE*/
    width: 100%;
    tbody,
    thead {
      display: block;
      /*Required for wrap to work correctly in IE*/
    }
    tr {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    >thead>tr>th,
    >tbody>tr>td {
      display: block;
    }
    >tbody {
      >tr>td {
        height: auto;
        max-width: none;
      }
    }
  }

  #pas-files-table {
    table.sc-table {
      tr {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  #filesModal {
    .modal {
      &__body {
        max-height: 100vh;

        &-wrapper {
          padding-top: 0;
          height: 100vh;
        }
      }
    }
  }

  .pas-detail-list {
    &__column {
        .cell {
            align-items: flex-end !important;;
        }

        .sc-tooltip__content {
            .content__description {
                font-family: $font-family-base;
                font-size: 12px !important;
                line-height:  12px !important;
                .specialist {
                    font-weight: bold;
                }
            }
        }
    }
  }
}

agm-map.geolocalization .agm-map-container-inner.sebm-google-map-container-inner {
  box-shadow: 3px 3px 8px 0px rgba(0, 0, 0, 0.24);
}

@mixin make-grid-gutters($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      .no-gutters-#{$breakpoint}.row {
        margin-right: 0;
        margin-left: 0;
        [class*='col-'] {
          padding-right: 0;
          padding-left: 0;
        }
      }
      .no-gutters-#{$breakpoint}.container {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

@mixin make-hidden($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      .hidden-#{$breakpoint}-down,
      .hidden-#{$breakpoint} {
        display: none !important;
      }
    }
    @include media-breakpoint-up($breakpoint) {
      .hidden-#{$breakpoint}-up {
        display: none !important;
      }
    }
  }
}

@include make-grid-gutters();
@include make-hidden();
@mixin set_mobile_widths {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      @each $size in map-keys($sizes) {
        .w-#{$breakpoint}-#{$size} {
          width: $size + unquote('%') !important;
          max-width: $size + unquote('%') !important;
        }
      }
    }
  }
}

@mixin set_mobile_heights {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      @each $size in map-keys($sizes) {
        .h-#{$breakpoint}-#{$size} {
          height: $size + unquote('%') !important;
          max-height: $size + unquote('%') !important;
        }
      }
    }
  }
}

@include set_mobile_widths();
@include set_mobile_heights();


$size: 0;

@for $i from 1 through 20 {
    $size: $i*5;
    .cell-w-#{$size} {
        width: #{$size}+'%';
    }
}

.service-order__buttons-container {
  .sc-button {
    min-width: 90px;
  }
}

.sc-button--link:focus {
    outline: none;
}

.sc-aside-claim-tracking__log {
  .tracking-log__item:last-child {
    .tracking-log__item-title {
      font-weight: bold;
    }
  }
}

.accordion-with-modal {
    .row.accordion {
        > :last-child {
            z-index: 3 !important;
        }
    }
}

.label-section {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: .75rem;
}
